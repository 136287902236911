import './App.css';
import background from "./images/background.png"

function App() {
  const x = setInterval(() => {
    const now = new Date().getTime();
    const distance = Date.parse("2023 05 01 12:00:00") - now;
  
    // Calculate remaining time
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
    // Display remaining time
    const timer = document.getElementById("timer");
    timer.innerHTML = `${days == 0 ? "" : days}${days == 0 ? "" : "d : "}${hours <= 9 ? 0 : ""}${hours}h : ${minutes <= 9 ? 0 : ""}${minutes}m : ${seconds <= 9 ? 0 : ""}${seconds}s`;
  
    // Check if the countdown has ended
    if (distance < 0) {
      clearInterval(x);
      timer.innerHTML = "The mint will start<br/>in a few minutes!<br/>Get ready!";
    }
  }, 1000);

  return (
    <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      backgroundColor: "black"
    }}>
      <div 
      className='webContainer'
      style={{
        backgroundImage: `url(${background})`,
        height: "100%",
        width: "100%",
        backgroundSize: "250%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign:"center",
        color: "#196028",
        fontSize: "48px",
        fontFamily: "Ubuntu-Bold",
      }}>
        <div id="timer"></div>
      </div>
    </div>
  );
}

export default App;